let trackerUrls: string[] | undefined = ["wss://chat-wss.jojmatic.com"]

if (process.env.REACT_APP_TRACKER_URL) {
  trackerUrls.unshift(process.env.REACT_APP_TRACKER_URL)
}

if (!trackerUrls.length) {
  trackerUrls = undefined
}

export { trackerUrls }
